import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import './Navbar.css'
import {HiMenuAlt4, HiX} from 'react-icons/hi';
import {motion} from 'framer-motion';
import {CgMenuGridO} from 'react-icons/cg';
import vrajPathLogo from '../../images/vrajpath_logo.svg'

const Navbar = () => {
    const [isActive, setIsActive] = useState('/');
    const [toggle, setToggle] = useState(false);

    const handleLinkClick = () => { 
        window.scrollTo(0, 0);
        setToggle(false);
      };

    var prevScrollpos = window.pageYOffset;

    window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("app__navbar").style.top = "0";
    } else {
      // document.getElementById("navbar").style.position = "fixed"
      document.getElementById("app__navbar").style.top = "-150px";
    }
    prevScrollpos = currentScrollPos;
  }

    return (<>
        <nav className='app__navbar' id='app__navbar'>
            <div> 
                <div>
                    <Link to={`/`}><img src={vrajPathLogo} /></Link>
                </div>
                <div className='nav_tags'>
                    <Link to={`/`}><h5>Home</h5></Link>
                    <Link to={`/services`}><h5>Services</h5></Link>
                    <Link to={`/our-clients`}><h5>Our Clients</h5></Link>
                    <Link to={`/resources`}><h5>Resources</h5></Link>
                    <Link to={`/contact`}><h5>Contact Us</h5></Link>
                </div>
            </div>
            <div> 
                <Link to={`/enquiry`}><h5 style={{color: "#141414"}}>Get Quotation</h5></Link>
                <hr style={{border: "1px solid #141414"}}></hr>
            </div>
        </nav> 
        <div  className='app__navbar-menu'>
                <CgMenuGridO onClick={() => setToggle(true)}/>

                {
                    toggle && (
                        <motion.div
                            whileInView={{x:[300,0]}}
                            transition={{duration:0.85,ease: 'easeInOut'}}
                        >
                            <HiX onClick={() => setToggle(false)} />
                            <ul className='app__menu_link'>
                            {/* {['/', 'About Us','/our-services'].map((item)=> (
                            <li  key={item}>
                               <a href={``} onClick={() => setToggle(false)}>{item} </a>
                            </li>
                         ))} */}
                              <Link className='linksss' to={`/`} onClick={handleLinkClick}><h5>Home</h5></Link>
                              <Link className='linksss' to={`/services`} onClick={handleLinkClick}><h5>Services</h5></Link>
                              <Link className='linksss' to={`/our-clients`} onClick={handleLinkClick}><h5>Our Clients</h5></Link>
                              <Link className='linksss' to={`/resources`} onClick={handleLinkClick}><h5>Resources</h5></Link>
                              <Link className='linksss' to={`/contact`} onClick={handleLinkClick}><h5>Contact Us</h5></Link>
                              <Link className='linksss' to={`/enquiry`} onClick={handleLinkClick}><h5>Get Quotation</h5></Link>
                         </ul>
                        </motion.div>
                    )
                } 
         </div>
    </>
    )
}

export default Navbar
