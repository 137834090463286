import React from "react";
import "./Clients.css";
import tata from "../../images/tata.svg";
import lt from "../../images/l&t.svg";
import globalbees from "../../images/globalbees.svg";
import instinct from "../../images/instinct.svg";
import Navbar from "../Navbar/Navbar";

const Clients = () => {
  return (
    <>
      <div className="app__our_clients">
        {/* <div className='row justify-content-center' >
            <div class="col-md-3">
              <div class="card " id='client-card'>
                <img src="Layer_1.svg" class="card-img" alt="..." width={'108px'} height={'78px'} />
              </div>
            </div>
            <div class="col-md-3" >
              <div class="card " id='client-card'>
                <img src="path2983.svg" class="card-img" alt="..." width={'72px'} height={'72px'} />
              </div>
            </div>
            <div class="col-md-3">
              <div class="card " id='client-card'>
                <img src="Rectangle.png" class="card-img" alt="..." />
              </div>
            </div>
            <div class="col-md-3">
              <div class="card " id='client-card'>
                <img src="unnamed (1).png" class="card-img" alt="..." />
              </div>
            </div>
          </div> */}

        <div
          className="clients__logos"
          style={{
            display: "flex",
            minHeight: "11rem",
            justifyContent: "space-between",
            margin: "0 15%",
            padding: '5% 0',
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "1rem 2rem",
            alignItems: "center"
          }}
        >
          {/* <img src={tata} style={{ objectFit: "contain" }} />
          <img src={lt} /> */}
          <img src={globalbees} />
          <img src={instinct} />
        </div>
      </div>
    </>
  );
};

export default Clients;
