import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios'
import "./ServicesPage.css";
import bglines from "../../images/bglines.svg";
import { APIURL } from "../../Constants";



const ServicesPage = () => {
  const [isActive, setIsActive] = useState();
  const [serviceData, setServiceData] = useState();
  const location = useLocation();

  const handleCardClick = (key) => {
    if (isActive === key) {
      setIsActive();
    } else {
      setIsActive(key);
    }
  };

  const getServices =  () => {
     axios.get(`${APIURL}/services/get`)
    .then(function (response) {
      
      setServiceData(response.data);
    })
    .catch(function (error) {
      
    })
  }


  useEffect(() => {    
    getServices()
  }, [])

  

  return (
    <>
      <div className="app__services">
        <div className="services__header">
          <img
            src={bglines}
            className="bglines"
          />
          <div>
            <p className="services_headline">make</p>
          </div>
        </div>
        <div className="services__container">
          {serviceData?.map((item, key) => {
            return (
              <div
                style={{
                  minHeight: "350px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  justifyContent: "space-between",
                  zIndex: isActive === key ? "100" : "",
                  cursor: "pointer",
                }}
                onMouseEnter={() => handleCardClick(key)}
                onMouseLeave={() => handleCardClick(key)}
              >
                <div
                  style={{ width: "80%", marginTop: "5%", marginLeft: "5%" }}
                >
                  <img style={{width: "75px"}} src={item.image} />
                  <p className="card__title">{item.title}</p>
                  <p className="card__subTitle">{item.subTitle}</p>
                </div>
                {isActive === key ? (
                  <>
                    <Link to={`/service/${item.identifier}`}>
                      <div
                        style={{
                          height: "4rem",
                          width: "auto",
                          backgroundColor: "#1E1E1E",
                          color: "white",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="proceed"
                      >
                        <p style={{ fontSize: "1.3rem", marginTop: "1rem" }}>
                          Proceed
                        </p>
                      </div>
                    </Link>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ServicesPage;
