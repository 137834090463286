import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import { APIURL } from '../../Constants/index.js';
import {FiShare2} from 'react-icons/fi'
import {FcLike} from 'react-icons/fc'
import {BiCommentDetail} from 'react-icons/bi'
import {AiOutlineLike} from 'react-icons/ai'
import {AiFillLike} from 'react-icons/ai'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Toaster, Toast, toast} from 'react-hot-toast'
import Accordion from 'react-bootstrap/Accordion';
import { motion } from "framer-motion"
import { fadeIn } from '../../Constants/motion.js';
import './ProductDetail.css'
import productBack from '../../images/productBack2.svg'
import TextTransition, { presets } from "react-text-transition";

const TEXTS = ["Experiences", "Celebrations", "Possibilities"];

const ProductDetail = () => {
    window.scrollTo(0, 0);
    const [index, setIndex] = React.useState(0);

    const [activeTab, setActiveTab] = useState("")

    const {productname} = useParams();
    const location = useLocation();

    const [article, setArticle] = useState("");
    const [productDetails, setProductDetails] = useState("")


    const handleShareBtn =() =>{
        toast.success('Link is Copied to the Clipboard');
    }
    const handleHandleCardClick = (tab) => {
        if(activeTab !== tab){
            setActiveTab(tab);
        } else {
            setActiveTab("");
        }
        
    }

    const handleClick = (btn) =>{

    }


    const getServices =  () => {
        axios.get(`${APIURL}/productDetails/get/${productname}`)
       .then(function (response) {
         
         setProductDetails(response.data);
       })
       .catch(function (error) {
         
       })
     }
   
   
     useEffect(() => {    
       getServices()
       const intervalId = setInterval(
        () => setIndex((index) => index + 1),
        2000 // every 3 seconds
      );
      return () => clearTimeout(intervalId);
     }, [])
   
  return (

    <div >
    <div className="landing-box">
    <div className="d-flex justify-content-center align-items-center flex-column font-weight-bold display-1">
        Unleash Vritual
        <TextTransition springConfig={presets.gentle}>
          <div className="text-center">{TEXTS[index % TEXTS.length]}</div>
        </TextTransition>
    </div>
        <button className='getBtn'><Link to={`/enquiry`}>Get Started</Link></button>
    </div>;
    
        <div className='product__details'>
            <div style={{width: "100%", height: "auto"}}>
                 <div  className='card__header'>
                    <div>
                        <p style={{fontSize: "5rem",  fontWeight: "400"}}>01</p>
                    </div>
                    <div  className='card__headline'>
                        <p  className='card_title'>Overview</p>
                        <p style={{fontSize: "1.4rem"}} >{productDetails?.overview?.subTitle}</p>
                    </div>
                    <div>
                        <div className='plus_container' onClick={() => handleHandleCardClick("overview")}>
                            <div className={`plus ${activeTab === "overview" ? "expand" : ""}`}>
                                <div className='plus-vertical'></div>
                                <div className='plus-horizontal'></div>
                            </div>
                            {/* <div className={`minus ${activeTab ? "expand" : ""}`}>
                                <div className='minus-horizontal'></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <motion.div variants={fadeIn} >
                    {
                        activeTab === "overview" ? <>
                            <div className='card_container'>
                                <div className='container_items'>
                                    {
                                        productDetails?.overview?.data.map((item, key) => {
                                            return(<>
                                                <p style={{fontSize: "2rem", fontWeight: "200"}}>{item?.headline}</p>
                                                <br/>
                                                {
                                                    item?.restData?.map((data, key) => {
                                                        return(<>
                                                            {
                                                                item.dataType === "paragraph" ? <>                                                                
                                                                    <p>{data}</p>
                                                                </>:<></>
                                                            }
                                                            {
                                                                item.dataType === "bulletPoints" ? <>                                                                
                                                                    <li>{data}</li>
                                                                </>:<></>
                                                            }
                                                        </>)
                                                    })
                                                }
                                            </>)
                                        })
                                    }
                                </div>
                            </div>
                        </> :<></>
                    }
                </motion.div>
            </div>
            <div style={{width: "100%", height: "auto"}}>
                 <div  className='card__header'>
                    <div>
                        <p style={{fontSize: "5rem",  fontWeight: "400"}}>02</p>
                    </div>
                    <div  className='card__headline'>
                        <p  className='card_title'>Product Specifications & Usage</p>
                        <p style={{fontSize: "1.4rem"}} >{productDetails?.specifications?.subTitle}</p>
                    </div>
                    <div>
                         <div className='plus_container' onClick={() => handleHandleCardClick("sepecs")}>
                            <div className={`plus ${activeTab === "sepecs" ? "expand" : ""}`}>
                                <div className='plus-vertical'></div>
                                <div className='plus-horizontal'></div>
                            </div>
                            {/* <div className={`minus ${activeTab ? "expand" : ""}`}>
                                <div className='minus-horizontal'></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {
                    activeTab === "sepecs" ? <>
                        <div>
                        <div className='card_container'>
                            <div className='container_items'>
                                {
                                    productDetails?.specifications?.data?.map((item, key) => {
                                        return(<>
                                            <p style={{fontSize: "2rem", fontWeight: "200"}}>{item?.headline}</p>
                                            <br/>
                                            {
                                                item?.restData?.map((data, key) => {
                                                    return(<>
                                                        {
                                                            item?.dataType === "paragraph" ? <>                                                                
                                                                <p>{data}</p>
                                                            </>:<></>
                                                        }
                                                        {
                                                            item?.dataType === "bulletPoints" ? <>                                                                
                                                                <li >{data}</li>
                                                                <br/>
                                                            </>:<></>
                                                        }
                                                    </>)
                                                })
                                            }
                                        </>)
                                    })
                                }
                            </div>
                        </div>
                        </div>
                    </> :<></>
                }
            </div>
            <div style={{width: "100%", height: "auto"}}>
                 <div  className='card__header'>
                    <div>
                        <p style={{fontSize: "5rem",  fontWeight: "400"}}>03</p>
                    </div>
                    <div className='card__headline'>
                        <p  className='card_title'>Product Features</p>
                        <p style={{fontSize: "1.4rem"}} >{productDetails?.features?.subTitle}</p>
                    </div>
                    <div>
                         <div className='plus_container' onClick={() => handleHandleCardClick("features")}>
                            <div className={`plus ${activeTab === "features" ? "expand" : ""}`}>
                                <div className='plus-vertical'></div>
                                <div className='plus-horizontal'></div>
                            </div>
                            {/* <div className={`minus ${activeTab ? "expand" : ""}`}>
                                <div className='minus-horizontal'></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                {
                    activeTab === "features" ? <>
                        <div>
                            <div className='card_container'>
                                <div className='container_items'>
                                    {
                                        productDetails?.features?.data.map((item, key) => {
                                            return(<>
                                                <p style={{fontSize: "2rem", fontWeight: "200"}}>{item?.headline}</p>
                                                <br/>
                                                {
                                                    item?.restData?.map((data, key) => {
                                                        return(<>
                                                            {
                                                                item?.dataType === "paragraph" ? <>                                                                
                                                                    <p>{data}</p>
                                                                </>:<></>
                                                            }
                                                            {
                                                                item?.dataType === "bulletPoints" ? <>                                                                
                                                                    <li >{data}</li>
                                                                    <br/>
                                                                </>:<></>
                                                            }
                                                        </>)
                                                    })
                                                }
                                            </>)
                                        })
                                    }
                                </div>
                            </div>                        
                        </div>
                    </> :<></>
                }
            </div>
            <div style={{width: "100%", height: "auto"}}>
                 <div className='card__header'>
                    <div>
                        <p style={{fontSize: "5rem",  fontWeight: "400"}}>04</p>
                    </div>
                    <div className='card__headline'>
                        <p  className='card_title'>Benefits & Offers</p>
                        <p style={{fontSize: "1.4rem"}} >{productDetails?.benefits?.subTitle}</p>
                    </div>
                    <div>
                         <div className='plus_container' onClick={() => handleHandleCardClick("benefits")}>
                            <div className={`plus ${activeTab === "benefits" ? "expand" : ""}`}>
                                <div className='plus-vertical'></div>
                                <div className='plus-horizontal'></div>
                            </div>
                            {/* <div className={`minus ${activeTab ? "expand" : ""}`}>
                                <div className='minus-horizontal'></div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div>
                          {
                            activeTab === "benefits" ? <>
                                <div>
                                    <div className='card_container'>                        
                                        <div className='container_items'>
                                            {
                                                productDetails?.benefits?.data.map((item, key) => {
                                                    return(<>
                                                        <p style={{fontSize: "2rem", fontWeight: "200"}}>{item?.headline}</p>
                                                        <br/>
                                                        {
                                                            item?.restData?.map((data, key) => {
                                                                return(<>
                                                                    {
                                                                        item?.dataType === "paragraph" ? <>                                                                
                                                                            <p>{data}</p>
                                                                        </>:<></>
                                                                    }
                                                                    {
                                                                        item?.dataType === "bulletPoints" ? <>                                                                
                                                                            <li >{data}</li>
                                                                            <br/>
                                                                        </>:<></>
                                                                    }
                                                                </>)
                                                            })
                                                        }
                                                    </>)
                                                })
                                            }
                                        </div>
                                    </div>                        
                                </div>
                            </> :<></>
                        }          
                </div>

            </div>
           

        </div>
        {/* <div className='app__product_details'>
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Accordion Item #1</Accordion.Header>
                <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Accordion Item #2</Accordion.Header>
                <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>
        </div> */}
    </div>
  )
}

export default ProductDetail