import React, {useState, useEffect} from 'react';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import { APIURL } from '../../Constants/index.js';
import {FiShare2} from 'react-icons/fi'
import {FcLike} from 'react-icons/fc'
import {BiCommentDetail} from 'react-icons/bi'
import {AiOutlineLike} from 'react-icons/ai'
import {AiFillLike} from 'react-icons/ai'
import moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Toaster, Toast, toast} from 'react-hot-toast'
import Avatar from 'react-avatar';
import Navbar from '../Navbar/Navbar.js';


const BlogDetails = () => {
    window.scrollTo(0, 0);

    const [article, setArticle] = useState();
    const [similarArticles, setSimilarArticles] = useState([])
    const [isLiked, setIsLiked] = useState(false);
    const [isShared, setIsShared] = useState(false);
    const [addComment, setAddComment] = useState("");
    const [allComments, setAllComments] = useState([]);

    const location = useLocation();

    const {articleId, identifier} = useParams();
    // useDocumentTitle(`${identifier}`)
 //   console.log(articleId)

 //   console.log(location.pathname)

    const [isMobile, setIsMobile] = useState(false);

 
    //choose the screen size 
    const handleResize = () => {
      if (window.innerWidth < 720) {
          setIsMobile(true)
       //   console.log("less than 720")
       //   console.log(isMobile)
      } else {
          setIsMobile(false)
       //   console.log("more than 720")
       //   console.log(isMobile)
      }
    }


    // create an event listener
    useEffect(() => {
      window.addEventListener("resize", handleResize)
      
    })


    
   
    const updateLikes = (istrue) => {
        if(istrue === true){
            article.likes = (article.likes) ? article.likes + 1 : 1;            
        } else {
            article.likes = (article.likes) ? article.likes - 1 : 1; 
        }

      axios.patch(`${APIURL}/article/${article._id}/update`, {
            likes: article.likes
            
      },
      {
      headers: {
          authtoken: localStorage.getItem('userToken')
      }
      })
      .then(function (response) {
        localStorage.setItem(`isLiked_${articleId}`, istrue);
     //   console.log(localStorage.getItem(`isLiked_${articleId}`))
      })
      .catch(function (error) {
       
      });
}

    const handleSubmitComment = () =>{
        
        // item.metaData.upvotedBy.push(localStorage.getItem('user_id'))
        // if (!item.metaData.upvotedBy) {
        //     item.metaData.upvotedBy = [];
        // }
        // if(!article.comments){
        //     article.comments= [];
        // }
        // article.comments.push(addComment);
        // axios.patch(`${APIURL}/article/${article._id}/update`, {
        //     comments: article.comments
            
        // },
        // {
        // headers: {
        //     authtoken: localStorage.getItem('userToken')
        // }
        // })
        // .then(function (response) {
        //     localStorage.setItem(`isLiked_${articleId}`, istrue);
        //  //   console.log(localStorage.getItem(`isLiked_${articleId}`))
        // })
        // .catch(function (error) {
        
        // });
    }
const handleSubmit = (event) => {
    event.preventDefault();

    handleSubmitComment();
    }
   
    const updateShares = () => {
        if(isShared === true){
           
            
        } else {
            article.shares = (article.shares) ? article.shares + 1 : 1;  
            axios.patch(`${APIURL}/article/${article._id}/update`, {
                shares: article.shares
                
          },
          {
          headers: {
              authtoken: localStorage.getItem('userToken')
          }
          })
          .then(function (response) {
            setIsShared(true)
          })
          .catch(function (error) {
           
          });
        }

   
}
    const handleShareBtn =() =>{
        toast.success('Link is Copied to the Clipboard');
        updateShares();
    }
    
    const handleLike = () => {
        
        if(isLiked === true){
            setIsLiked(false);
            updateLikes(false);
        } else {
            setIsLiked(true)
            updateLikes(true);
        }
    }
   


    useEffect(() => {
        if(localStorage.getItem(`isLiked_${articleId}`)){
            setIsLiked(true);
        }
        
        const getSimilarArticles = async (categoryId) => {
            axios.get(`${APIURL}/article/getAll`, {
                headers: {
                authtoken: localStorage.getItem('userToken')
                },
                params: {
                    category: categoryId,
                    status: "active"
                }
            })
            . then(function (response) {
                response.data.data = response.data.data.filter(item => item._id !== article._id)
                setSimilarArticles(response.data.data);
            
                return response.data.data;
        
            })
            .catch(function (error) {
                
            });  
        }
            const getArticle = async () => {
                axios.get(`${APIURL}/article/${articleId}/getOne`, {
                    headers: {
                    authtoken: localStorage.getItem('userToken')
                    },
                    params: {
                    status: "active"
                    }
                })
                . then(function (response) {
                    setArticle(response.data.data);
                    getSimilarArticles(response.data.data.category._id);
                    return response.data.data;
                    
                })
                .catch(function (error) {
                    
                });  
            }

         

        setArticle(getArticle());
        // setSimilarArticles(getSimilarArticles());'
      
      }, [])

    //   getSimilarArticles();
    // useDocumentTitle(`${article?.title}`)
   //   console.log(article)
   //   console.log(article?.summary)
   //   console.log(similarArticles)
  return (

    <>
    <div className='app__blogdetails'>
        <div className='blog__block1'>
           <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 10%', fontFamily: 'Poppins-Regular' }}>
                    <div style={{display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center'}}>
                        <div>
                          <Avatar name={article?.author?.authorName} round={true} size="50" textSizeRatio={0.1} style={{fontSize: '0.6rem !important'}}/>
                        </div>
                        <div style={{marginLeft: '1rem', width:'100%'}}>
                            <p style={{marginTop: '1rem', width: '100%', fontSize: '0.8rem',letterSpacing: "1.5px"}}>{article?.author?.authorName}</p>
                            <p style={{marginTop: '-1rem', fontSize: '0.8rem',letterSpacing: "1.5px"}}>{article?.author?.bio}</p>
                        </div>
                    </div>
            </div>

            

        </div>

        <div className='blog__block2'>
                    <div className='article__sharebtn'>
                         <button className='share__btn_article' style={{backgroundColor: `${article?.category?.primaryColor}`, border: 'none', color:'white', borderRadius: '5px', textTransform: 'uppercase', fontSize:'0.8rem', padding: '1% 2%', cursor: 'default', letterSpacing: "1.5px"}}>{article?.category?.name}</button>
                         {/* <CopyToClipboard text={window.location.href} ><button className='share__btn_article' onClick={handleShareBtn} style={{marginLeft: '2rem'}}><FiShare2 style={{marginRight: '1rem', scale: '1.4'}}/>Share</button></CopyToClipboard> */}
                         
                    </div>
            <div className='article__header'>
                <h6 >{article?.title}</h6>
                {/* <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center'}}>
                        <div>
                          <Avatar name={article?.author?.authorName} round={true} size="50" textSizeRatio={0.1} style={{fontSize: '0.6rem !important'}}/>
                        </div>
                        <div style={{marginLeft: '1rem'}}>
                            <p style={{marginTop: '1rem'}}>{article?.author?.authorName}</p>
                            <p style={{marginTop: '-1rem'}}>{article?.author?.bio}</p>
                        </div>
                    </div>
                    <div style={{marginLeft: '1rem', textAlign: 'right', alignItems: 'right'}}>
                        <p>{moment(article?.publishDate).format("MMMM Do YYYY, h:mm:ss a")}</p>
                    </div>
                </div> */}
                <p style={{color: "#3b3c3c", fontweight: "300"}}>{article?.description}</p>
                   
                    <div className='article__sharebtn'>
                                <div className="event__matrix3">
                                    <div style={{marginBottom: '1rem', display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'center'}}>
                                      <h4 style={{fontFamily: "Poppins-SemiBold", fontSize: "1.1rem"}}><span onClick={()=> {handleLike()}} >{isLiked ? <><AiFillLike style={{marginRight: '0.2rem', marginTop:'-0.3rem', cursor:'pointer'}}/></> : <><AiOutlineLike style={{marginLeft: '0.2rem', marginTop:'-0.3rem', cursor:'pointer'}}/></>}</span> {article?.likes ? article?.likes : "0"}</h4>
                                      {/* <p style={{marginTop: '-0.5rem'}}>Views</p> */}
                                    </div>
                                    <div style={{marginBottom: '1rem', display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'center', fontSize: '1rem', textTransform: 'uppercase'}}>
                                      <h4 style={{fontFamily: "Poppins-SemiBold", fontSize: "1.1rem"}}><span style={{fontFamily:"Poppins-Regular", fontSize: '0.7rem', textTransform: 'uppercase', marginTop: '-1rem'}}><FiShare2 style={{marginRight: '0.2rem', marginTop:'-0.5rem', height: '1.4rem', width: '1.4rem'}}/></span>{article?.shares ? article?.shares : "0"} </h4>
                                      {/* <p style={{marginTop: '-0.5rem'}}></p> */}
                                    </div>
                                    <div style={{marginBottom: '1rem', display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent:'center', fontSize: '1rem', textTransform: 'uppercase'}}>
                                      <h4 style={{fontFamily: "Poppins-SemiBold", fontSize: "1.1rem"}}><span style={{fontFamily:"Poppins-Regular", fontSize: '0.7rem', textTransform: 'uppercase'}}><BiCommentDetail style={{marginRight: '0.2rem', marginTop:'-0.5rem', height: '1.4rem', width: '1.4rem'}}/></span>{article?.comments?.length ? article?.comments?.length : "0"} </h4>
                                      {/* <p style={{marginTop: '-0.5rem'}}></p> */}
                                    </div>
                                </div>
                         {/* <button className='share__btn_article' style={{backgroundColor: `${article?.category?.primaryColor}`, border: 'none', color:'white', borderRadius: '5px', textTransform: 'uppercase'}}>{article?.category?.name}</button> */}
                         <CopyToClipboard text={window.location.href} ><button className='share__btn_article' onClick={handleShareBtn} style={{marginLeft: '0rem'}}><FiShare2 style={{marginRight: '1rem', scale: '1.4'}}/>Share</button></CopyToClipboard>
                         
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '2%', fontFamily: 'Poppins-Regular' }} className="statics_matrix_mobile">
                        <div style={{display: 'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center'}}>
                        <div>
                          <Avatar name={article?.author?.authorName} round={true} size="50" textSizeRatio={0.1} style={{fontSize: '0.6rem !important'}}/>
                        </div>
                            <div style={{marginLeft: '1rem', width:'100%'}}>
                                <p style={{marginTop: '1rem', width: '100%', fontSize: '0.8rem'}}>{article?.author?.authorName}</p>
                                <p style={{marginTop: '-1rem', fontSize: '0.8rem'}}>{article?.author?.bio}</p>
                            </div>
                         </div>
                         <div style={{marginLeft: '1rem', textAlign: 'right', alignItems: 'right', textAlign: 'center', fontSize: '0.8rem'}}>
                        <p>{moment(article?.publishDate).format("MMMM Do YYYY, h:mm:ss a")}</p>
                       </div>
                     </div>
            </div>
            {/* <div style={{marginTop: '10%'}}>
                <img src={article?.bannerImage?.imageUrl} className="article__banner"/>
            </div> */}

            <div style={{marginTop: "10%"}}>
            {
                article?.content?.map((item, key) => {
                  return(<>
                  {
                    item?.dataType === "Headline" && <div className="content_headline"> 
                      {
                        item?.data.map((content, key) => {
                          return(<>
                            <h2>{content}</h2>
                          </>)
                        })
                      }
                    </div> 
                  }
                  {
                    item?.dataType === "Paragraph" && <div className="content_paragraph">
                      {
                        item?.data.map((content, key) => {
                          return(<>
                            <h4>{content}</h4>
                          </>)
                        })
                      }
                    </div>
                  }
                  {
                    item?.dataType === "Points" && <div className="content_points">
                      {
                        item?.data.map((content, key) => {
                          return(<>
                             <li>{content}</li>
                             <br/>
                          </>)
                        })
                      }
                    </div>
                  }
                  {
                    item?.dataType === "Image" && <div className="content_imgae">
                      {
                        item?.data.map((content, key) => {
                          return(<>
                            <img 
                              src={content}
                              alt={article?.title}
                              className="content_image_image"
                            />
                          </>)
                        })
                      }
                    </div>
                  }
                  </>)
                })
              }

  
            </div>

                {
                    similarArticles.length > 0 ? <>
                        <div className='event__Cards'>
                            <p>You might like this also</p>
                            <div className='event__card__image'>
                                {similarArticles?.map((item, key)=> {
                                return(
                                    <>
                                    <div className='event_card' key={key}>
                                        <Link exact to={'/article/' + item.articleId +  '/' + item.identifier }target="_blank" rel='noopener noreferrer' ><img className='events__img' src={item?.bannerImage?.imageUrl} /></Link>
                                        <p>{item.title}</p>
                                    </div>
                                    </>
                                );
                                })}
                            </div>
                        </div>
                    </> : <>
                    
                    </>
                }
                

                {/* <div style={{marginTop: "3rem"}}>
                    <p style={{fontSize: '2rem', fontFamily: "Poppins-Medium"}}>Add Comments</p>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className='add_comment_div'>
                             
                                <div className='form__item2'>            
                                     <BiCommentDetail style={{marginLeft: '0rem', marginTop:'-0.5rem', height: '3rem', width: '3rem'}}/><textarea type="text" placeholder='Type Your Comment' value={addComment} onChange={(e) => setAddComment(e.target.value)}/>
                                </div>

                                <div className='form__submit2'>
                                <input type="submit" value='Publish'/>
                            </div>
                            </div>
                            
                        </form>
                    </div>
                    <div>
                        <p style={{fontSize: '2rem', fontFamily: "Poppins-Medium", marginTop: '1rem'}}>All Comments</p>
                        {similarArticles?.map((item, key) => {
                            return(
                                <>
                                    <div className='item__comment'>
                                        <div className='comment_details'>
                                                <p>Name</p>
                                                <p style={{marginTop: '-1rem'}}>Date</p>
                                        </div>
                                        
                                        <div className='comment__comment'>
                                                <p>this is very nice blog</p>
                                        </div>
                                        <div>
                                        <h4 style={{fontFamily: "Poppins-SemiBold"}}>{article?.likes}<span onClick={()=> {handleLike()}} >{isLiked ? <><AiFillLike style={{marginLeft: '0.2rem', marginTop:'-0.3rem', cursor:'pointer'}}/></> : <><AiOutlineLike style={{marginLeft: '0.2rem', marginTop:'-0.3rem', cursor:'pointer'}}/></>}</span> </h4>
                                        </div>

                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div> */}
        </div>




        <div className='blog__block3'>
                    <div style={{marginLeft: '1rem', textAlign: 'right', alignItems: 'right', textAlign: 'center', fontSize: '0.8rem'}}>
                        <p style={{letterSpacing: "1.5px"}}>{moment(article?.publishDate).format("MMMM Do YYYY, h:mm:ss a")}</p>


                    </div>
{/* 
                    <amp-ad width="100vw" height="320"
                        type="adsense"
                        data-ad-client="ca-pub-2039413588695073"
                        data-ad-slot="5208072959"
                        data-auto-format="rspv"
                        data-full-width="">
                    <div overflow=""></div>
                    </amp-ad> */}
              
                                                  
        </div>
        <Toaster       
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            iconTheme: {
              primary: '#141414',
              secondary: 'white',
            },
          },
        }}
        />

    </div>
    </>
  )

    }
export default BlogDetails