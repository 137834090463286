import React, {useState, useEffect, useCallback} from 'react'
import {Link} from 'react-router-dom'
import down_btn from '../../images/down_btn.svg'
import hero_img from '../../images/hero_img3.svg'
import blocks from '../../images/boxs.svg'
import AnchorLink from "react-anchor-link-smooth-scroll";
import Footer from "../Contactus/Footer"
import vrajpath_logo from '../../images/vrajpath_logo.svg'
import './Homepage.css'

const Homepage = () => {
    const values=['Innovation','Simplified','Collaboration']
    const [value,setvalue]=useState('Collaboration')

    const shuffle=useCallback(()=>{
        const index=Math.floor(Math.random()* values.length);
        setvalue(values[index]);
      },[])

    useEffect(()=>{
    const intervalID=setInterval(shuffle,2000);
    return ()=> clearInterval(intervalID)
    },[shuffle])

  return (
    <div className="text-center" >
        <div className='app__homepage'>
            <div className='mobile_logo'>
                <img
                    src={vrajpath_logo}
                />
            </div>
            <div>
                <h1 className='changing_text'>{value}</h1>
                <h6 className='sub_text'>For Productive <span>Integrated Solutions</span></h6>
                
                <AnchorLink href='#home_stats'><img src={down_btn} /></AnchorLink>
            </div>
            <div style={{marginTop: "3rem", maxWidth: "max-content"}}>
                <img src={hero_img} className="hero_img" id='hero_img'/>
                <hr className='hr_home'></hr>
                <div className='home_stats' id='home_stats'>
                    <div>
                        <h2 style={{fontSize: "50px", textAlign: "center", whiteSpace: "nowrap", fontWeight: '600'}}>15+</h2>
                        <p class="-text" style={{fontSize: '1.2rem'}}>Growing Business with us</p>
                    </div>
                    <div>
                        <h2 style={{fontSize: "50px", textAlign: "center", whiteSpace: "nowrap", fontWeight: '600'}}>65+</h2>
                        <p class="-text" style={{fontSize: '1.2rem'}}>Projects delivered on time</p>
                    </div>
                    <div>
                        <h2 style={{fontSize: "50px", textAlign: "center", whiteSpace: "nowrap", fontWeight: '600'}}>30+</h2>
                        <p class="-text" style={{fontSize: '1.2rem'}}>Indian & abroad clients coverage</p>
                    </div>
                </div>
            </div>
            <div style={{marginTop: "5rem"}}>
                <h6 className='text_3'>Continue To</h6>
                <h6 className='text_4'>Grow With Technology</h6>
                <hr className="hr_home_2"></hr>
            </div>
            <div className='home_info'>
                <div className='home_info_container'>
                    <div className='block1'>
                        <h5>Facilitate Support in Industry Growth</h5>
                    </div>
                    <div className='block2'>
                        <p>Everyone's needs are different. That's why, from the moment you will get connect with us , it will become easier to know you and your requirements personally, providing smart, easy solutions for your business to boost development and success in industry.</p>
                    </div>
                </div>
                <img src={blocks} style={{margin: "3rem 0"}} />
                <div className='home_info_container'>
                    <div className='block1'>
                        <h5>More And Better Services and Solutions</h5>
                    </div>
                    <div className='block2'>
                        <p>We know there are times when you may want additional help to upgrade your business. So we made it easy for you in terms with better services and solutions for your successful business.</p>
                    </div>
                </div>
                <img src={blocks} style={{margin: "3rem 0"}}  />
                <div className='home_info_container'>
                    <div className='block1'>
                        <h5>Productive Collaboration With Outsource IT Teams</h5>
                    </div>
                    <div className='block2'>
                        <p>Running a business can sometimes be overwhelming. There are many things to oversee, plans to reassess, and goals to achieve. We made it easy for you by outsourcing our IT Team to make it more productive.</p>
                    </div>
                </div>
            </div>
            {/* <div className='block_2'>
                <h3>Why Us</h3>
                <p>Because</p>
                <p style={{marginTop: "-1rem"}}>We Know Good Product Means, Good Business</p>
                <div style={{display: "flex", justifyContent: "space-around", gap: "5rem", width: "100%"}}>
                    <div style={{minWidth: "15%"}}>1</div>
                    <div style={{minWidth: "15%"}}>1</div>
                    <div style={{minWidth: "15%"}}>1</div>
                </div>
            </div> */}
            <div className='block_3'>
                <div>
                    <h1>We have a “strategic” plan its called doing things....</h1>
                </div>
                <div>
                    <p>Let's Make a Strategy</p>
                    <button className='meetBtn'><Link to={`/enquiry`}>Get Started</Link></button>
                </div>
            </div>
        </div>
            <div className='row no-gutters'>
                    <div className='col-md-1 ml-n4 socials' style={{ marginTop: '-8rem', position: 'fixed', top: '30.125rem' }}>
                        <ul style={{ "list-style-type": "none" }} id='icons'>
                            <li className='mb-5'>
                                <i class="fa-brands fa-facebook-f" style={{ fontSize: '1.5rem' }}></i>
                            </li>
                            <li className='mb-5'>
                                <i class="fa-brands fa-instagram " style={{ fontSize: '1.5rem' }}></i>
                            </li>
                            <li className='mb-5'>
                                <i class="fa-brands fa-linkedin-in " style={{ fontSize: '1.5rem' }}></i>
                            </li>
                            <li className='mb-5'>
                                <i class="fa-brands fa-youtube " style={{ fontSize: '1.5rem' }}></i>
                            </li>
                            <li className=''>
                                <i class="fa-brands fa-twitter " style={{ fontSize: '1.5rem' }}></i>
                            </li>
                        </ul>
                    </div>
                </div>
    </div>
  )
}

export default Homepage