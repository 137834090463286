import React from 'react'

const footer = () => {
    return (
        <div className='container'>
            <p className='text-center'>
                <span style={{ 'font-weight': '600', 'font-size': '20px' }}>ANKIT KUMAR SHARMA</span>
            </p>
            <p className='text-center mt-n2' style={{paddingBottom:'0rem'}}>
                <span style={{ color: '#909090', fontSize: '16px', fontWeight: '500', letterSpacing: "0.4em",textTransform: "uppercase"  }}>Founder of VrajPath</span>
            </p>
        </div>

    )
}

export default footer

