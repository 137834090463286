import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Ourclients.css";
import Header from "./header";
import Clients from "./Clients";
import Paragraph from "./Para.js";
import Footer from "./footer";
import Title from "./Title";
import Navbar from "../Navbar/Navbar";
const Ourclients = () => {
  return (
    <>
      <div
        id="major-container"
        style={{
          backgroundColor: "#efefef",
          height: "100vh",
          // marginTop: "5rem",
          paddingTop: "14rem",
          width: "100%",
        }}
      >
        {/* <Header /> */}
        {/* <Title /> */}

        <div id="minor-container">
          <Clients />
        </div>

        <Paragraph />
        <Footer />
      </div>
    </>
  );
};

export default Ourclients;
