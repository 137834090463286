import React from 'react'
import {Link} from 'react-router-dom'
import './Footer.css'
import blacklogo from "../../images/blacklogo.svg";
import textlogo from "../../images/textlogo.svg";
import { useLocation } from 'react-router-dom';
const Footer = () => {
    const location = useLocation();
    console.log(location.pathname)
    return (
        <div>
    
        {
            location.pathname === "/services" ? <>
            
            </> : <>
                <div className='container-fluid' id='main'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-md-2'>
                                <div className=' ' id='footer-c'>
                                    <div className='d-flex'style={{ display: "flex", justifyContent: "center", paddingRight: "2rem" }}>
                                        <img src={blacklogo} style={{}} />
                                        <img src={textlogo} style={{}} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <div className='footer_tags' id='footer-c'  style={{display: "flex", flexDirection: "column", textAlign: "center"}}>
                                    <p style={{color: "black"}}> <Link to={`/privacy-policy`}>Privacy Policy</Link> &nbsp;| <Link to={`/terms-&-conditions`}>Terms & Conditions</Link> &nbsp; | <Link to={`/cancellation-&-refund`}>Cancellation & Refund</Link> &nbsp; | <Link to={`/contact`}>Contact Us</Link></p>
                                    <span className='text-center'><i class="fa-brands fa-facebook fa-lg pr-3"></i> <i class="fa-brands fa-instagram fa-lg pr-3"></i><i class="fa-brands fa-linkedin-in fa-lg pr-3 "></i><i class="fa-brands fa-youtube fa-lg pr-3"></i> <i class="fa-brands fa-twitter fa-lg pr-3 "></i></span>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className='' id='footer-c'>
                                    <p className='-text' style={{whiteSpace: "nowrap"}}><span>Any Queries?call on.</span></p>
                                    <span className='mt-n3'>+91-9818527999</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center' style={{fontWeight: "600"}}>
                        Vrajpath Itech LLP @ 2023 All Rights Reserved.
                    </div>
                </div>
            </>
        }

        </div>
    )
}

export default Footer


// <div className="block4__footer">
// <div style={{ display: "flex", justifyContent: "center" }}>

//   <img src={textlogo} style={{}} />
// </div>
// <div
//   style={{
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//   }}
// >
//   <div
//     style={{
//       display: "flex",
//       justifyContent: "space-between",
//       gap: "1rem",
//     }}
//     className="footer__links"
//   >
//     <div>
//       <p>Privacy Policy</p>
//     </div>
//     <div>
//       <p>|</p>
//     </div>
//     <div>
//       <p>Terms & Conditions</p>
//     </div>
//     <div>
//       <p>|</p>
//     </div>
//     <div>
//       <p>Cancellation & Refund</p>
//     </div>
//     <div>
//       <p>|</p>
//     </div>
//     <div>
//       <p>Contact Us</p>
//     </div>
//   </div>
//   <div className="footer__socials">
//     <div>
//       <img
//         src={ImFacebook}
//         style={{ height: "2rem", width: "2rem" }}
//       />
//     </div>
//     <div>
//       <img
//         src={AiOutlineInstagram}
//         style={{ height: "2rem", width: "2rem" }}
//       />
//     </div>
//     <div>
//       <img
//         src={FaLinkedinIn}
//         style={{ height: "2rem", width: "2rem" }}
//       />
//     </div>
//     <div>
//       <img
//         src={AiOutlineYoutube}
//         style={{ height: "2rem", width: "2rem" }}
//       />
//     </div>
//     <div>
//       <img
//         src={FaTwitter}
//         style={{ height: "2rem", width: "2rem" }}
//       />
//     </div>
//   </div>
//   <div style={{ marginTop: "2rem" }}>
//     <p className="rights">
//       Vrajpath Itech LLP © 2023 All Rights Reserved
//     </p>
//   </div>
// </div>
// <div>
//   <div
//     style={{
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "right",
//     }}
//   >
//     <p className="call">Any queries? Call on</p>
//     <p className="no">+91-9818527999</p>
//   </div>
// </div>
// </div>
