import React from "react";

// INTERNAL IMPORT
import './LandingPage.css'
import Introduction from "./Introduction";

const LandingPage = () => {
  return <div className="landing-box">
    <Introduction/>
  </div>;
};

export default LandingPage;
