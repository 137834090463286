import React, { useEffect } from 'react'

const Product = () => {
    useEffect(()=> {
      fetchData();
    },[]);

    const fetchData = async () => {
      const data = await fetch("https://www.vrajpath.com/api/productDetails/get/lead-managment-system");
      const json = await data.json();
      console.log(json);
    }

  return (
    <div>Product</div>
  )
}

export default Product;