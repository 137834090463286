import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import './Details.css'
import Navbar from '../Navbar/Navbar'
import web from '../../images/web.svg'
import next from '../../images/next.svg'
import next2 from '../../images/next2.svg'
import { APIURL } from "../../Constants";

const Details = () => {
    window.scrollTo(0, 0);
    const [serviceDetail, setServiceDetail] = useState()
    const {identifier} = useParams();
    const location = useLocation();


    const handleClickArrow = () => {

    }

//     identifier
// : 
// "business-development-and-digital-marketing"
// image
// : 
// ['']
// images
// : 
// []
// services
// : 
// (3) [{…}, {…}, {…}]
// subTitle
// : 
// " And Digital Marketing"
// title
// : 
// "Business Development"
// updatedAt
// : 
// "2023-04-12T08:22:18.048Z"

    const getServices =  () => {
        axios.get(`${APIURL}/services/get/${identifier}`)
       .then(function (response) {
         
         setServiceDetail(response.data.services);
       })
       .catch(function (error) {
         
       })
     }
   
   
     useEffect(() => {    
       getServices()
     }, [])
   

  return (
    <>
    <div className="text-center" >
            <div style={{textAlign: "left", display: "flex", flexDirection: "column",justifyContent: "left", alignItems: "left", marginTop: "10%", marginBottom: "5rem",marginLeft: "15%" ,  width: "80%"}} className="servicedetail">
                <div>
                    <p className='headline__details'>{serviceDetail?.title}</p>
                    <p className='subheadline__details'>{serviceDetail?.subTitle}</p>        
                </div>
                <Link to={`${location.pathname}/enquiry`} style={{textDecoration: "none"}}>
                    <div style={{display: "flex",gap: "1rem"}}>
                            <div style={{display: "flex"}}>
                                <img src={next}/>
                            </div>
                            <div>
                                <p className='desc__details'>Want to get customized Solutions ?</p>
                                <p className='desc__details2'>Get Quotation</p>
                                
                            </div>
                    </div>
                </Link>
                <div style={{width: "100%", marginBottom: "7rem"}}>

                    {
                        serviceDetail?.products?.map((item, key) =>{
                            return(<>
                                <div style={{display: "flex", justifyContent: "space-between", marginTop: "6rem", alignItems: "center", width: "80%", flexWrap: "wrap",gap: "8rem 0rem"}}>
                                    <div className="subServiceBlock1">
                                        <p className='desc__details3'>{item?.name}</p>
                                        <p className='desc__details4'>{item?.description}</p>
                                        <Link to={`${location.pathname}/${item?.identifier}`}>
                                            <div className='arrow'>
                                                <img src={next2}/>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="subServiceBlock2" style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative"}}>
                                        {/* <div style={{height: "120px", width: "120px", backgroundColor: "white", borderRadius: "50%", position: "relative", right: '-70%', top: '-69px', display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            <img src={web} /> 
                                        </div> */}

                                        <img src={item?.image} style={{height: "13rem", borderRadius: "3%"}}/>
                                    </div>
                                </div>
                            </>)
                        })
                    }
                   



                    {/* <div style={{display: "flex", justifyContent: "space-between", marginTop: "6rem", alignItems: "center", width: "80%", flexWrap: "wrap"}}>
                        <div style={{width: "60%", display: "flex", flexDirection: "row", justifyContent: "flex-start", position: "relative"}}>
                            <div style={{height: "8rem", width: "8rem", backgroundColor: "white", borderRadius: "8rem", position: "relative", right: '-70%', top: '-69px', display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <img src={web} /> 
                            </div> 
                            <img src='https://s3-alpha-sig.figma.com/img/0215/198b/7c65ddced8566ca0e45883ae8060bc81?Expires=1681689600&Signature=N79UHBGTLF91LGBKdYu7bWGsZrBThfayEIf94zuq96FQKR-ENjS3e3iZaWjrwnLA9EYYL8JejdhDGfnggT45IaXc9dWwLiht7UNykiaEx54XLpcl68lrTi817tRu-ih2WpQyNEYbssz70j29Sp7HeVhuzTE5k9s1mr8YzTXCkY9moeyCeppCf5ADS6F~cn214PNXcoOOLVyJybtBhuXMrqE2pAzry5irxCV7jXncIWcm4mFYDOMauYeIBNHPBqr55HFXJ6IzyXWoeyEEcZdyDc4ox4HJ00UdO1OSxdMwP8fTDb0LkXoaKeIj-M25sp8kRlWOBn5XWiOFNlDJJJGGJQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4' style={{height: "13rem", borderRadius: "3%"}}/>
                        </div>
                        <div style={{width: "40%"}}>
                            <p className='desc__details3'>E-Commerce Platform</p>
                            <p className='desc__details4'>An HRM solution can help manage employee information, payroll, benefits, performance evaluations, and more. Helps streamline HR processes, improve efficiency, and support overall HR strategy.</p>
                            <div className='arrow'>
                                <img src={next2}/>
                            </div>
                        </div>
                    </div> */}



                    {/* <div style={{display: "flex", justifyContent: "space-between", marginTop: "6rem", alignItems: "center", width: "80%", flexWrap: "wrap"}}>
                        <div style={{width: "40%"}}>
                            <p className='desc__details3'>HIVE - HRM Solution</p>
                            <p className='desc__details4'>a comprehensive solution for managing an online store, including inventory management, payment processing, shipping and tax calculation, and customer management. Helps businesses establish an online presence, reach a wider audience, and grow their sales.</p>
                            <div className='arrow'>
                                <img src={next2}/>
                            </div>
                        </div>
                        <div style={{width: "60%", display: "flex", flexDirection: "row", justifyContent: "flex-end", position: "relative"}}>
                            <div style={{height: "8rem", width: "8rem", backgroundColor: "white", borderRadius: "8rem", position: "relative", right: '-70%', top: '-69px', display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <img src={web} /> 
                            </div>
                            <img src='https://s3-alpha-sig.figma.com/img/0696/466b/ad7224de235ba719e65a1a82d17291d4?Expires=1681689600&Signature=p1gIwfkOC5qvtByI2GWTlYO5jnVI4dWA7~wjMZqq8CO3sEEgfcDrng633xZGRrRe1n-S2IfpU0y4~4HkUUeCbWTYTC6OTHBZtppPDnKzbECoPnPIKpb9VG8SGN0aNNlJDkuwblE5vftNaMUmj2a16K7JIsRW38w4FDkBfJr1mNe82ZukINR1sQXuwdx50BRRRVirowFSCaOYvibCFdAqePBCBCxkmPr1BlpuwuYKj5roCNbO2ahTT6wYqWnB~ANVSTdE3tjxx4fXKcK10V7m6msvZp-SDQeoUge9E2kKkLxGPTcTbEjctALXxYl9iQdZ8xuQDxxCtGGSJxyfA1~2Jg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4' style={{height: "13rem", borderRadius: "3%"}}/>
                        </div>
                    </div> */}



                    {/* <div style={{display: "flex", justifyContent: "space-between", marginTop: "10rem", alignItems: "center", width: "100%", flexWrap: "wrap"}}>
                        <div style={{width: "50%"}}>
                            <div style={{height: "8rem", width: "8rem", backgroundColor: "white", borderRadius: "8rem", position: "absolute", left: -50, marginTop:"-5rem", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <img src={web} /> 
                            </div>

                            <img src='https://s3-alpha-sig.figma.com/img/0215/198b/7c65ddced8566ca0e45883ae8060bc81?Expires=1681689600&Signature=N79UHBGTLF91LGBKdYu7bWGsZrBThfayEIf94zuq96FQKR-ENjS3e3iZaWjrwnLA9EYYL8JejdhDGfnggT45IaXc9dWwLiht7UNykiaEx54XLpcl68lrTi817tRu-ih2WpQyNEYbssz70j29Sp7HeVhuzTE5k9s1mr8YzTXCkY9moeyCeppCf5ADS6F~cn214PNXcoOOLVyJybtBhuXMrqE2pAzry5irxCV7jXncIWcm4mFYDOMauYeIBNHPBqr55HFXJ6IzyXWoeyEEcZdyDc4ox4HJ00UdO1OSxdMwP8fTDb0LkXoaKeIj-M25sp8kRlWOBn5XWiOFNlDJJJGGJQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4' style={{height: "13rem", borderRadius: "3%"}}/>
                        </div>
                        <div style={{width: "50%"}}>
                            <p className='desc__details3'>E-Commerce Platform</p>
                            <p className='desc__details4'>a comprehensive solution for managing an online store, including inventory management, payment processing, shipping and tax calculation, and customer management. Helps businesses establish an online presence, reach a wider audience, and grow their sales.</p>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", marginTop: "10rem", alignItems: "center", width: "100%", flexWrap: "wrap"}}>
                        <div style={{width: "50%"}}>
                            <p className='desc__details3'>HIVE - HRM Solution</p>
                            <p className='desc__details4'>An HRM solution can help manage employee information, payroll, benefits, performance evaluations, and more. Helps streamline HR processes, improve efficiency, and support overall HR strategy.</p>
                        </div>
                        <div style={{width: "50%"}}>
                            <div style={{height: "8rem", width: "8rem", backgroundColor: "white", borderRadius: "8rem", position: "absolute", right: 150, marginTop:"-5rem", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <img src={web} />
                            </div>
 
                            <img src='https://s3-alpha-sig.figma.com/img/0696/466b/ad7224de235ba719e65a1a82d17291d4?Expires=1681689600&Signature=p1gIwfkOC5qvtByI2GWTlYO5jnVI4dWA7~wjMZqq8CO3sEEgfcDrng633xZGRrRe1n-S2IfpU0y4~4HkUUeCbWTYTC6OTHBZtppPDnKzbECoPnPIKpb9VG8SGN0aNNlJDkuwblE5vftNaMUmj2a16K7JIsRW38w4FDkBfJr1mNe82ZukINR1sQXuwdx50BRRRVirowFSCaOYvibCFdAqePBCBCxkmPr1BlpuwuYKj5roCNbO2ahTT6wYqWnB~ANVSTdE3tjxx4fXKcK10V7m6msvZp-SDQeoUge9E2kKkLxGPTcTbEjctALXxYl9iQdZ8xuQDxxCtGGSJxyfA1~2Jg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4' style={{height: "13rem", borderRadius: "3%"}}/>
                        </div>
                    </div> */}
                </div>

            </div>
            <div className='row no-gutters'>
                <div className='col-md-1 ml-n4 socials' style={{ marginTop: '-8rem', position: 'absolute', top: '30.125rem' }}>
                    <ul style={{ "list-style-type": "none" }} id='icons'>
                        <li className='mb-5'>
                            <i class="fa-brands fa-facebook-f" style={{ fontSize: '1.5rem' }}></i>
                        </li>
                        <li className='mb-5'>
                            <i class="fa-brands fa-instagram " style={{ fontSize: '1.5rem' }}></i>
                        </li>
                        <li className='mb-5'>
                            <i class="fa-brands fa-linkedin-in " style={{ fontSize: '1.5rem' }}></i>
                        </li>
                        <li className='mb-5'>
                            <i class="fa-brands fa-youtube " style={{ fontSize: '1.5rem' }}></i>
                        </li>
                        <li className=''>
                            <i class="fa-brands fa-twitter " style={{ fontSize: '1.5rem' }}></i>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default Details