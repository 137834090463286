import React from "react";
import TextTransition, { presets } from "react-text-transition";

const TEXTS = ["Experiences", "Celebrations", "Possibilities"];

const Introduction = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center flex-column font-weight-bold display-1">
        Unleash Vritual
        <TextTransition springConfig={presets.gentle}>
          <div className="text-center">{TEXTS[index % TEXTS.length]}</div>
        </TextTransition>
    </div>
  );
};

export default Introduction;
