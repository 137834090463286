import React from 'react'
import './Para.css'
const Para = () => {
  return (
    <div className='container-fluid'>
        <div className='container text-center m-ml-4 m-mr-4 pb-5 pt-1' id='paragraph' style={{marginTop: "5%"}}>
          “Majority of the companies are outsourcing their development work to
          other IT companies so that they can focus on business. We have a strong
          development team who do it on contract basis.”
        </div>

        <div className='container pb-5'>
          <hr style={{ 'width': '164px', "background-color": "#909090", "height": "0.5px" }}></hr>
        </div>
      
    </div>
  )
}

export default Para
