
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Ourclients from './components/OurClients/Ourclients';
import ContactUsPage from './components/Contactus/ContactUsPage';
import ServicesPage from './components/Services/ServicesPage';
import Navbar from './components/Navbar/Navbar';
import Details from './components/ServiceDetail/Details';
import ProductDetail from './components/ProductDetail/ProductDetail';
import Enquiry from './components/Enquiry/Enquiry';
import Blog from './components/Blog/Blog';
import BlogDetails from './components/Blog/BlogDetails';
import Homepage from './components/Homepage/Homepage';
import Privacy from './components/Legal/Privacy';
import TermsAndConditions from './components/Legal/TermsAndConditions';
import CancellationAndRefund from './components/Legal/CancellationAndRefund';
import Footer from './components/Contactus/Footer';
import LandingPage from './components/LandingPage/LandingPage';
import Product from './components/Product/Product';

function App() {
  return (
    <>
    <Router>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Homepage/>}></Route>
        <Route path='/contact' element={<ContactUsPage/>}></Route>
        <Route path='/services' element={<ServicesPage/>}></Route>
        <Route path='/our-clients' element={<Ourclients/>}></Route>
        <Route path='/resources' element={<Blog/>}></Route>
        <Route path='/resources/article/:articleId/:identifier' element={<BlogDetails/>}></Route>
        <Route path='/service/:identifier' element={<Details/>}></Route>
        <Route path='/enquiry' element={<Enquiry/>}></Route>
        <Route path='/service/:identifier/enquiry' element={<Enquiry/>}></Route>
        <Route path='/service/:identifier/:productname' element={<ProductDetail/>}></Route>
        <Route path='/privacy-policy' element={<Privacy/>}></Route>
        <Route path='/terms-&-conditions' element={<TermsAndConditions/>}></Route>
        <Route path='/cancellation-&-refund' element={<CancellationAndRefund/>}></Route>
        <Route path='/landing' element={<LandingPage/>}></Route>
        <Route path='/get-started' element={<Product/>}></Route>
        

      </Routes>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
