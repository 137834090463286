import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import { HiLocationMarker } from "react-icons/hi";
import Footer from "./Footer";
import axios from "axios";
import GoogleMapReact from "google-map-react";
import mail from "../../images/mail.svg";
import phoneimg from "../../images/phone.svg";
import location from "../../images/location.svg";
import left from "../../images/left.svg";
import right from "../../images/right.svg";
import { APIURL } from "../../Constants";

import "./ContactUsPage.css";

const ContactUsPage = () => {
  window.scrollTo(0, 0);
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [email_newsletter, setEmail_newsletter] = useState("");
  const [message, setMessage] = useState("");

  const [deliverd, setDeliverd] = useState(false);
  const [deliverd_newsletter, setDeliverd_newsletter] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  const sendMessage = (e) => {
    e.preventDefault()
    axios.post(`${APIURL}/messages/create`, {
      firstName: firstName,
      lastName: lastName,
      message: message
    })
    .then(function (response) {
      setDeliverd(true)
    })
    .catch(function (error) {
    });
  }

  const submitNewsLetterEmail = (e) => {
    if(checked) {
          e.preventDefault()
          axios.post(`${APIURL}newsletter/create`, {
            email: email_newsletter
          })
          .then(function (response) {
            setDeliverd_newsletter(true)
          })
          .catch(function (error) {
          });
    }

  }

  const AnyReactComponent = ({ text }) => (
    <div>
      <HiLocationMarker style={{ height: "2rem", width: "2rem" }} />
      {text}
    </div>
  );
  const defaultProps = {
    center: {
      lat: 28.63749,
      lng: 77.43464,
    },
    zoom: 14,
  };
  return (
    <>
      <div>
        <div className="contactus__1">
          <div className="app__map">
            <div className="map__layout">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCMANwkNsfj8j63O73NupJkQdWOhgVseQE",
                }}
                options={{
                  styles: [
                    {
                      elementType: "geometry",
                      stylers: [{ color: "#f5f5f5" }],
                    },
                    {
                      elementType: "labels.icon",
                      stylers: [{ visibility: "off" }],
                    },
                    {
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#616161" }],
                    },
                    {
                      elementType: "labels.text.stroke",
                      stylers: [{ color: "#f5f5f5" }],
                    },
                    {
                      featureType: "administrative.land_parcel",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#bdbdbd" }],
                    },
                    {
                      featureType: "poi",
                      elementType: "geometry",
                      stylers: [{ color: "#eeeeee" }],
                    },
                    {
                      featureType: "poi",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#757575" }],
                    },
                    {
                      featureType: "poi.park",
                      elementType: "geometry",
                      stylers: [{ color: "#e5e5e5" }],
                    },
                    {
                      featureType: "poi.park",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#9e9e9e" }],
                    },
                    {
                      featureType: "road",
                      elementType: "geometry",
                      stylers: [{ color: "#ffffff" }],
                    },
                    {
                      featureType: "road.arterial",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#757575" }],
                    },
                    {
                      featureType: "road.highway",
                      elementType: "geometry",
                      stylers: [{ color: "#dadada" }],
                    },
                    {
                      featureType: "road.highway",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#616161" }],
                    },
                    {
                      featureType: "road.local",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#9e9e9e" }],
                    },
                    {
                      featureType: "transit.line",
                      elementType: "geometry",
                      stylers: [{ color: "#e5e5e5" }],
                    },
                    {
                      featureType: "transit.station",
                      elementType: "geometry",
                      stylers: [{ color: "#eeeeee" }],
                    },
                    {
                      featureType: "water",
                      elementType: "geometry",
                      stylers: [{ color: "#c9c9c9" }],
                    },
                    {
                      featureType: "water",
                      elementType: "labels.text.fill",
                      stylers: [{ color: "#9e9e9e" }],
                    },
                  ],
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <AnyReactComponent
                  lat={28.63749}
                  lng={77.43464}
                  text="VrajPath"
                />
              </GoogleMapReact>
            </div>
          </div>
          <div className="app__message">
            <p className="contact__header">Contact.</p>
            <p className="contact__header" style={{ marginTop: "0%" }}>
              Get In Touch,
            </p>
            <p
              className="contact__header"
              style={{
                marginTop: "-3%",
                fontSize: "1rem",
                textTransform: "uppercase",
                letterSpacing: "0.3em",
                fontWeight: "500",
              }}
            >
              leave us a message
            </p>
            {!deliverd ? (
              <>
                <form onSubmit={sendMessage} className="form_container">
                  <div style={{ marginTop: "2rem" }} className="form__block">
                    <div className="contact__item">
                      <p>First Name</p>
                      <div className="item__input">
                        {/* <BsFillPersonFill style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                        <input
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div
                      className="contact__item"
                    >
                      <p>Last Name</p>
                      <div className="item__input">
                        {/* <MdEmail style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                        <input
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="contact__item" style={{ marginTop: "2rem" }}>
                    <p>Message</p>
                    <textarea
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    ></textarea>
                  </div>
                  <div className="submit__btn ">
                    <input type="submit" value="Proceed" />
                  </div>
                </form>
              </>
            ) : (
              <>
                <div style={{ textAlign: "center", marginTop: "9rem" }}>
                  <p
                    style={{
                      fontSize: "2rem",
                      color: "#7D7D7D",
                      fontFamily: "Poppins",
                    }}
                  >
                    {" "}
                    We succesfully received
                  </p>
                  <p
                    style={{
                      fontSize: "2rem",
                      color: "#7D7D7D",
                      fontFamily: "Poppins",
                    }}
                  >
                    {" "}
                    your Message
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="contactus__2">
          <div className="bg__text">
            <p>Contact</p>
          </div>
          <div
            className="block2"
  
          >
            <div className="block2__header">
              <p>Quick.</p>
              <p>Support</p>
              <p className="sub__headline">
                Connect with us, we're here to help
              </p>
            </div>
            <div
              className="contact_tags"
            >
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                }}
                className="contact_tag"
              >
                <img src={location} />
                <p
                  style={{
                    fontSize: "30px",
                    fontWeight: "600",
                    marginTop: "1rem",
                  }}
                >
                  Visit Us
                </p>
                <p style={{ fontSize: "15px", fontWeight: "400" }}>
                  Galaxy Diamond Plaza, Gaur City-2, Greater Noida, Uttar
                  Pradesh 201309
                </p>
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                }}
                className="contact_tag"
              >
                <img src={phoneimg} />
                <p
                  style={{
                    fontSize: "30px",
                    fontWeight: "600",
                    marginTop: "1rem",
                  }}
                >
                  Call Us
                </p>
                <p style={{ fontSize: "15px", fontWeight: "400" }}>
                  +91- 9818527999
                </p>
              </div>
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                }}
                className="contact_tag"
              >
                <img src={mail} />
                <p
                  style={{
                    fontSize: "30px",
                    fontWeight: "600",
                    marginTop: "1rem",
                  }}
                >
                  Email Us
                </p>
                <p style={{ fontSize: "15px", fontWeight: "400" }}>
                  support@vrajpath.com
                </p>
              </div>
            </div>
          </div>
          <div className="block3">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5% 10%",
              }}
            >
              <div>
                <img src={left} />
              </div>
              <div style={{ maxWidth: "100%" }}>
                <p className="footer__line1">Revamp Your</p>
                <p className="footer__line2">Business</p>
                <p className="footer__line3">with us today</p>
                <div>
                  {
                    deliverd_newsletter ? <>
                      <div>
                      <div style={{ textAlign: "center", marginTop: "9rem" }}>
                          <p
                            style={{
                              fontSize: "2rem",
                              color: "#7D7D7D",
                              fontFamily: "Poppins",
                            }}
                          >
                            {" "}
                            We succesfully received
                          </p>
                          <p
                            style={{
                              fontSize: "2rem",
                              color: "#7D7D7D",
                              fontFamily: "Poppins",
                            }}
                          >
                            {" "}
                            your Message
                          </p>
                        </div>
                      </div>
                    </> : <>
                        <div className="contact__item3" style={{ marginTop: "5rem" }}>
                          <div className="item__input">
                            {/* <MdEmail style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                            <input
                              type="text"
                              value={email_newsletter}
                              placeholder="Your email address"
                              onChange={(e) => setEmail_newsletter(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "5rem",
                            gap: "1rem",
                          }}
                        >
                          <div>
                            <input
                              type="checkbox"
                              checked={checked}
                              onChange={handleChange}
                              className="checkBox"
                            />
                          </div>
                          <div style={{ maxWidth: "20rem" }}>
                            <p>I agree with Privacy Policy and Terms of Conditions</p>
                          </div>
                          <div>
                            <button className="register_button" onClick={submitNewsLetterEmail}>Register</button>
                          </div>
                        </div>    
                    </>
                  }

                </div>
           
              </div>
              <div>
                <img src={right} />
              </div>
            </div>
             {/* <Footer/> */}
          </div>
          <div className="block3_mobile">
            <div style={{ maxWidth: "100%" }}>
                  <p className="footer__line1">Revamp Your</p>
                  <p className="footer__line2">Business</p>
                  <p className="footer__line3">with us today</p>
                  <div>
                    {
                      deliverd_newsletter ? <>
                        <div>
                        <div style={{ textAlign: "center", marginTop: "9rem" }}>
                            <p
                              style={{
                                fontSize: "2rem",
                                color: "#7D7D7D",
                                fontFamily: "Poppins",
                              }}
                            >
                              {" "}
                              We succesfully received
                            </p>
                            <p
                              style={{
                                fontSize: "2rem",
                                color: "#7D7D7D",
                                fontFamily: "Poppins",
                              }}
                            >
                              {" "}
                              your Message
                            </p>
                          </div>
                        </div>
                      </> : <>
                          <div className="contact__item3" style={{ marginTop: "5rem" }}>
                            <div className="item__input" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              {/* <MdEmail style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                              <input
                                type="text"
                                value={email_newsletter}
                                placeholder="Your email address"
                                onChange={(e) => setEmail_newsletter(e.target.value)}
                                required
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "1rem",
                              padding: "2rem",
                              flexDirection: "column"
                            }}
                          >
                            <div style={{display : "flex", gap: "2rem"}}>
                              <input
                                type="checkbox"
                                checked={checked}
                                onChange={handleChange}
                                className="checkBox"
                              />
                              <div style={{ maxWidth: "20rem" }}>
                                <p>I agree with Privacy Policy and Terms of Conditions</p>
                              </div>
                            </div>
                            <div>
                              <button className="register_button" onClick={submitNewsLetterEmail}>Register</button>
                            </div>
                          </div>    
                      </>
                    }

                  </div>
            
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
