import React, {useState, useEffect} from 'react'
import axios from 'axios';
import vrajpath_pattern from '../../images/Vrajpath_pattern.svg';
import vrajpath_pattenText from '../../images/vrajpath_patterntext.svg'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { APIURL } from '../../Constants';
import './Enquiry.css'

const Enquiry = () => {
  window.scrollTo(0, 0);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [service, setService] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState();

  const [servicesData, setServicesData] = useState();

  const [gotQuatation, setGotQuatation] = useState(false);

  const getServices =  () => {
    axios.get(`${APIURL}/productDetails/get`)
   .then(function (response) {
     
     setServicesData(response.data)
   })
   .catch(function (error) {
     
   })
 }


 useEffect(() => {    
   getServices()
 }, [])

 
 const sendMessage = (e) =>{
   e.preventDefault()
   axios.post(`${APIURL}/quatation/create`, {
     name: name,
      service : service,
      phone : phone,
      email: email,
      message: message,
      budget : budget
    })
    .then(function (response) {
      setGotQuatation(true)
    })
    .catch(function (error) {
    });
  }
  return (
    <>
    <div className='app__enquiry'>
        <div className="bg__text2">
            <p>Quotation</p>
          </div>
        <div className='enquiry_logos'>
            <h2 className="contact__header">Collaboration,</h2>
            <h2 className="contact__header" style={{ marginTop: "0%"}}>
             Innovation Simplified!
            </h2>
            <p>Majority of the companies are outsourcing their development work so that they can focus on business..</p>
        </div>
        <div className='quotation_form'>
          {
            gotQuatation ? <>
              <div style={{padding: "3rem 4rem"}}>
                <h3>We Got Your Response We will get Back to you Soon.</h3>
              </div>
            </> : <>
              <h4 style={{fontSize: "2rem", marginTop: "2rem", color: "black"}}>Get Quotation</h4>
              <form onSubmit={sendMessage} className="form_container form_container2">
                      <div style={{ marginTop: "2rem" }} className="form__block">
                        <div className="contact__item contact_item2">
                          <p>Name</p>
                          <div className="item__input item_service">
                            {/* <BsFillPersonFill style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                            <input
                              type="text"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                              />
                          </div>
                        </div>
                        <div className="contact__item contact_item2">
                          <p>Phone</p>
                          <div className="item__input item_service">
                            {/* <BsFillPersonFill style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                            <input
                              type="number"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              required
                              />
                          </div>
                        </div>
                        <div className="contact__item contact_item2">
                          <p>Email</p>
                          <div className="item__input item_service">
                            {/* <BsFillPersonFill style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                            <input
                              type="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                              />
                          </div>
                        </div>

                        {/* <div className='item__input .form__dropdown'>            
                                <p style={{width: "100%", textAlign: "left", color: "black"}}>Service</p>
                                <Dropdown style={{marginTop: '1%'}}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {service === ""? "": service}
                                        
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {servicesData?.map((item, key) => {
                                          return(
                                            <Dropdown.Item onClick={() =>setService(item.name)}>{item.name}</Dropdown.Item>
                                            )
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div> */}
                            <div className='form__item3 form__dropdown'  >            
                            <p style={{width: "100%", textAlign: "left", color: "black"}}>Service</p>
                            <div className='item__input .form__dropdown'>   
                                <select id="cars" className='service_dropdown'>
                                 
                                      {servicesData?.map((item, key) => {
                                          return(
                                             <option value="volvo"onClick={() =>setService(item.name)}>{item.name}</option>
                                            )
                                        })}
                                </select>
                              </div>
                     
                        </div>


                      </div>
                      <div className="contact__item contact_item2" style={{ marginTop: "2rem" }}>
                        <p>Business Description</p>
                        <textarea
                          type="text"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                          ></textarea>
                      </div>
                      <div className="contact__item contact_item2" style={{marginTop: "5%"}}>
                          <p>Estimate Budget</p>
                          <div className="item__input item_service">
                            {/* <BsFillPersonFill style={{height: '2rem', width: '2rem', color: '#717171', marginTop: '-0.8rem', marginRight: '0.6rem'}}/> */}
                            <input
                              type="number"
                              value={budget}
                              onChange={(e) => setBudget(e.target.value)}
                              required
                              />
                          </div>
                        </div>
                      <div className="submit__btn margin__left__5_btn" style={{marginBottom: "2rem"}}>
                        <input type="submit" value="Proceed" />
                      </div>
                    </form>
            </>
          }
        </div>

    </div>
    </>
  )
}

export default Enquiry